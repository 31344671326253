<template >
    <div >
        <el-dialog title="商品列表" :visible.sync="dialogTableVisible" :close-on-click-modal="false"  append-to-body class="dialogClass"  width="1124px" :before-close="handleClose">
            <el-form :inline="true">
                <el-form-item label="商品名称" label-width="100">
                    <el-input
                            v-model="searchForm.storeGoodsName"
                            placeholder="商品名称"
                            clearable>
                    </el-input>
                </el-form-item >
                <el-form-item label="商品状态" label-width="100">
                    <el-select v-model="searchForm.status" clearable placeholder="选择商品状态">
                        <el-option label="新箱" value="0"></el-option>
                        <el-option label="预售" value="1"></el-option>
                        <el-option label="普通" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品类型" label-width="100">
                    <el-select v-model="searchForm.storeGoodsType" clearable placeholder="选择商品类型">
                        <el-option label="一番赏" value="0"></el-option>
                        <el-option label="自组抽奖" value="1"></el-option>
                        <el-option label="单品" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getStoreGoodsList" type="success"  >搜索</el-button>
                </el-form-item>
            </el-form>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
                @selection-change="handleSelectionChange">
            <el-table-column
                align="center"
                fixed="left"
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                    label="商品名称"
                    align="center"
                    prop="storeGoodsName"
                    width="220">
            </el-table-column>
            <el-table-column
                    label="商品封面"
                    align="center"
                    width="120">
                <template slot-scope="scope">
                    <el-avatar size="small" :src="scope.row.storeGoodsCover"></el-avatar>
                </template>
            </el-table-column>
                <el-table-column
                        prop="statusName"
                        align="center"
                        width="120"
                        label="商品状态">
                </el-table-column>
                <el-table-column
                        label="商品价格"
                        align="center"
                        prop="storeGoodsPrice"
                        width="120"
                ></el-table-column>
                <el-table-column
                        prop="residueCartons"
                        align="center"
                        width="150"
                        label="	剩余箱数">
                </el-table-column>
                <el-table-column
                        label="总箱数"
                        align="center"
                        prop="totalCartons"
                        width="150"
                ></el-table-column>
                <el-table-column
                        prop="storeGoodsType"
                        align="center"
                        label="商品类型"
                        width="148">
                    <template slot-scope="scope">
                        <el-tag size="small" v-if="scope.row.storeGoodsType === 0" type="success">一番赏</el-tag>
                        <el-tag size="small" v-else-if="scope.row.storeGoodsType === 1" type="info">自组抽奖</el-tag>
                        <el-tag size="small" v-else-if="scope.row.storeGoodsType===2" type="warning"> 单品</el-tag>
                    </template>
                </el-table-column>
             <el-table-column
                    prop="icon"
                    align="center"
                    width="100"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="selStoreGood(scope.row)">确定</el-button>
                </template>
            </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total"
                >
        </el-pagination>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "GetCouponsRelGood",
        data(){
            return{
                searchForm:{},
                tableData:[],
                multipleSelection:[],
                total: 0,
                size: 10,
                current: 1,
                dialogTableVisible:false,

            }
        },
        // created() {
        //     this.getStoreGoodsList()
        // },
        methods:{
            resetForm() {
                this.dialogTableVisible = false
                this.searchForm={}
                this.tableData=[]
                this.multipleSelection=[]
                this.total=0
                this.size=10
                this.current= 1
            },
            handleClose() {
                this.resetForm()
            },
             toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSizeChange(val) {
                this.size = val
                this.getStoreGoodsList()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getStoreGoodsList()
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit('refreshDataList',val)
            },
            getStoreGoodsList(){
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.$axios.post("/admin/coupons/getCouponsStoreGoods", this.searchForm).then(res=>{
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.pages
                    this.total = res.data.total
                })
            },
            selStoreGood(item) {
                this.$emit('refreshDataList',item)
                this.resetForm()
            },
            init(){
                this.dialogTableVisible=true;
                //this.getStoreGoodsList(relStoreGoodIdList)
                this.getStoreGoodsList();
            },
        }
    }
</script>

<style>
    .dialogClass .el-dialog__body {
        padding-bottom: 80px;
    }
</style>
